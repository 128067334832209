<template>
  <div class="title">
    <div>
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.title {
  background: #fafbfc;
  text-align: center;
  width: 100%;
  height: 200px;
  background-size: cover;
  position: relative;
  div {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      letter-spacing: 8px;
      line-height: 50px;
      font-family: Source Han Sans CN;
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      color: #2c3e50;
    }
    p {
      line-height: 24px;
      padding-top: 6px;
      text-align: center;
      font-family: PingFangSCBold;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 4px;
      color: #666666;
    }
  }
}
@media (max-width: 1023px) {
  .title {
    div {
      h1 {
        font-size: 30px;
        width: 80vw;
        margin: 0 auto;
      }
    }
  }
}
</style>